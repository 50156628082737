<template>
  <div class="e-kanban">
    <!--  <div>-->
    <!--    <Row :gutter="16">-->
    <!--      <Col span="12">-->
    <!--        <div-->
    <!--            class="xttz_par"-->
    <!--            style="-->
    <!--            height: 310px;-->
    <!--            border: 1px solid rgb(235, 235, 235);-->


    <!--            overflow: hidden;-->
    <!--          "-->
    <!--        >-->
    <!--          <div-->
    <!--              style="-->
    <!--              border-bottom: 1px solid rgb(235, 235, 235);-->
    <!--              height: 60px;-->
    <!--              line-height: 60px;-->
    <!--              font-size: 20px;-->
    <!--              font-weight: bold;-->
    <!--              padding-left: 20px;-->
    <!--            "-->
    <!--          >-->
    <!--            系统通知-->
    <!--          </div>-->
    <!--            <div-->
    <!--                v-for="(item, index) in systemList"-->
    <!--                :key="index"-->
    <!--                style="height: 50px; padding: 5px 20px"-->
    <!--            >-->
    <!--              <span-->
    <!--                  :title="item.content"-->
    <!--                  class="yi_hang_sheng"-->
    <!--                  style="-->
    <!--                  display: inline-block;-->
    <!--                  border-bottom: 1px dashed #e8e8e8;-->
    <!--                  height: 40px;-->
    <!--                  line-height: 40px;-->
    <!--                "-->
    <!--              >{{ item.content }}</span>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--      </Col>-->
    <!--      <Col span="12">-->
    <!--        <div-->
    <!--            class="xttz_par"-->
    <!--            style="-->
    <!--            height: 310px;-->
    <!--            border: 1px solid rgb(235, 235, 235);-->

    <!--          "-->
    <!--        >-->
    <!--          <div-->
    <!--              style="-->
    <!--              border-bottom: 1px solid rgb(235, 235, 235);-->
    <!--              height: 60px;-->
    <!--              line-height: 60px;-->
    <!--              font-size: 20px;-->
    <!--              font-weight: bold;-->
    <!--              padding-left: 20px;-->
    <!--            "-->
    <!--          >-->
    <!--            待办事项-->
    <!--          </div>-->
    <!--          <div-->
    <!--              v-for="(item, index) in payList"-->
    <!--              :key="index"-->
    <!--              class="xttz yi_hang_sheng"-->
    <!--              style="height: 50px; padding: 5px 20px"-->
    <!--          >-->
    <!--            <span-->
    <!--                class="yi_hang_sheng"-->
    <!--                style="-->
    <!--                width: 100%;-->
    <!--                display: inline-block;-->
    <!--                border-bottom: 1px dashed #e8e8e8;-->
    <!--                height: 40px;-->
    <!--                line-height: 40px;-->
    <!--              "-->
    <!--            >{{-->
    <!--                item.orderTypeName-->
    <!--              }}{{ item.orderSn }}&nbsp;&nbsp;&nbsp;&nbsp;<span-->
    <!--                  v-if="item.state == 'UNPAIED'"-->
    <!--                  style="color: #fe9203; cursor: pointer;padding-left: 10px"-->
    <!--                  @click="goPay(item)"-->
    <!--              >&nbsp;&nbsp;去支付</span-->
    <!--              ></span-->
    <!--            >-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </Col>-->
    <!--    </Row>-->
    <!--    <div class="qyzscq">-->
    <!--      <div-->
    <!--          style="-->
    <!--          height: 100px;-->
    <!--          line-height: 100px;-->
    <!--          font-size: 20px;-->
    <!--          font-weight: bold;-->
    <!--          padding-left: 20px;-->
    <!--        "-->
    <!--      >-->
    <!--        企业知识产权状况-->
    <!--      </div>-->
    <!--      <div-->
    <!--          style="-->
    <!--          height: 100px;-->
    <!--          line-height: 100px;-->
    <!--          background-color: rgb(243, 252, 252);-->
    <!--          font-size: 14px;-->
    <!--          padding-left: 40px;-->
    <!--        "-->
    <!--      >-->
    <!--        <Row :gutter="16">-->
    <!--          <Col span="5"-->
    <!--          >企业托管专利-->
    <!--            <span class="cq_span" style="color: rgb(54, 194, 207)">{{-->
    <!--                patentMonitorData.patentMonitorTotal-->
    <!--              }}</span>-->
    <!--            项-->
    <!--          </Col-->
    <!--          >-->
    <!--          <Col span="6"-->
    <!--          >其中: 发明专利-->
    <!--            <span class="cq_span">{{ patentMonitorData.fmPatentNum }}</span>-->
    <!--            项-->
    <!--          </Col-->
    <!--          >-->
    <!--          <Col span="5"-->
    <!--          >实用新型-->
    <!--            <span class="cq_span">{{ patentMonitorData.syPatentNum }}</span>-->
    <!--            项-->
    <!--          </Col-->
    <!--          >-->
    <!--          <Col span="5"-->
    <!--          >外观设计-->
    <!--            <span class="cq_span">{{ patentMonitorData.wgPatentNum }}</span>-->
    <!--            项-->
    <!--          </Col-->
    <!--          >-->
    <!--        </Row>-->
    <!--      </div>-->
    <!--      <div-->
    <!--          style="-->
    <!--          height: 300px;-->
    <!--          width: 95%;-->
    <!--          margin-left: 50%;-->
    <!--          transform: translateX(-50%);-->
    <!--          margin-top: 60px;-->
    <!--          padding-left: 60px;-->
    <!--        "-->
    <!--      >-->
    <!--        <Row :gutter="16">-->
    <!--          <Col span="6" style="width: 25%">-->
    <!--            <div class="big_circle blue_linear" style="">-->
    <!--              <div class="small_circle">-->
    <!--                <div>-->
    <!--                  <span style="font-size: 25px">0</span>&nbsp;项-->
    <!--                </div>-->

    <!--              </div>-->
    <!--            </div>-->
    <!--            <div-->
    <!--                style="-->
    <!--                text-align: center;-->
    <!--                width: 160px;-->
    <!--                height: 20px;-->
    <!--                line-height: 20px;-->
    <!--                font-size: 16px;-->
    <!--                font-weight: bold;-->
    <!--              "-->
    <!--            >-->
    <!--              状态变更提醒-->
    <!--            </div>-->
    <!--          </Col>-->
    <!--          <Col span="6" style="width: 25%">-->
    <!--            <div class="big_circle orange_linear">-->
    <!--              <div class="small_circle" style="background-color: #ffa47b">-->
    <!--                <div><span style="font-size: 25px">0</span>&nbsp;项</div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div-->
    <!--                style="-->
    <!--                text-align: center;-->
    <!--                width: 160px;-->
    <!--                height: 20px;-->
    <!--                line-height: 20px;-->
    <!--                font-size: 16px;-->
    <!--                font-weight: bold;-->
    <!--              "-->
    <!--            >-->
    <!--              年费状态预警-->
    <!--            </div>-->
    <!--          </Col>-->
    <!--          <Col span="6" style="width: 25%">-->
    <!--            <div class="big_circle green_linear">-->
    <!--              <div class="small_circle" style="background-color: #36c2cf">-->
    <!--                <div>-->
    <!--                            <span style="font-size: 25px;vertical-align: bottom">{{-->
    <!--                                patentMonitorData.annualFeeFinishNum-->
    <!--                              }}</span-->
    <!--                            >&nbsp;项-->
    <!--                </div>-->

    <!--              </div>-->
    <!--            </div>-->
    <!--            <div-->
    <!--                style="-->
    <!--                text-align: center;-->
    <!--                width: 160px;-->
    <!--                height: 20px;-->
    <!--                line-height: 20px;-->
    <!--                font-size: 16px;-->
    <!--                font-weight: bold;-->
    <!--              "-->
    <!--            >-->
    <!--              已缴纳年费-->
    <!--            </div>-->
    <!--          </Col>-->
    <!--          <Col span="6" style="width: 25%">-->
    <!--            <div class="big_circle extra_linear">-->
    <!--              <div class="small_circle" style="background-color: #33c26c">-->
    <!--                <div>-->
    <!--                         <span style="font-size: 25px">{{-->
    <!--                             patentMonitorData.totalMonitor-->
    <!--                           }}</span-->
    <!--                         >&nbsp;项-->
    <!--                </div>-->

    <!--              </div>-->
    <!--            </div>-->
    <!--            <div-->
    <!--                style="-->
    <!--                text-align: center;-->
    <!--                width: 160px;-->
    <!--                height: 20px;-->
    <!--                line-height: 20px;-->
    <!--                font-size: 16px;-->
    <!--                font-weight: bold;-->
    <!--              "-->
    <!--            >-->
    <!--              状态监控剩余-->
    <!--            </div>-->
    <!--          </Col>-->
    <!--        </Row>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="qytjzk">-->
    <!--      <div-->
    <!--          style="-->
    <!--          height: 100px;-->
    <!--          line-height: 100px;-->
    <!--          font-size: 20px;-->
    <!--          font-weight: bold;-->
    <!--          padding-left: 20px;-->
    <!--        "-->
    <!--      >-->
    <!--        企业体检状况-->
    <!--      </div>-->
    <!--      <div-->
    <!--          style="-->
    <!--          height: 100px;-->
    <!--          line-height: 100px;-->
    <!--          background-color: rgb(243, 252, 252);-->
    <!--          font-size: 14px;-->
    <!--          padding-left: 40px;-->
    <!--        "-->
    <!--      >-->
    <!--        <Row :gutter="16">-->
    <!--          <Col span="5"-->
    <!--          >高新企业技术<span-->
    <!--              class="cq_span"-->
    <!--              style="color: rgb(54, 194, 207)"-->
    <!--          >{{ evalResultData.totalScore || 0}}</span-->
    <!--          >-->
    <!--            分-->
    <!--          </Col-->
    <!--          >-->
    <!--          <Col span="6">科技小巨人企业 <span class="tj_span">未测评</span></Col>-->
    <!--          <Col span="6">科技型中小企业 <span class="tj_span">未测评</span></Col>-->
    <!--          <Col span="6">“独角兽“企业 <span class="tj_span">未测评</span></Col>-->
    <!--        </Row>-->
    <!--      </div>-->
    <!--      <div-->
    <!--          style="-->
    <!--          height: 300px;-->
    <!--          width: 95%;-->
    <!--          margin-left: 50%;-->
    <!--          transform: translateX(-50%);-->
    <!--          margin-top: 60px;-->
    <!--          padding-left: 60px;-->
    <!--        "-->
    <!--      >-->
    <!--        <Row :gutter="16">-->
    <!--          <Col span="6">-->
    <!--            <div class="circle">-->
    <!--              <i-circle :percent="evalResultData.baseScore || 0" dashboard stroke-color="#36C2CF">-->
    <!--                <div>-->
    <!--                          <span class="demo-circle-inner" style="font-size: 38px;vertical-align: text-top"-->
    <!--                          >{{ evalResultData.baseScore || 0 }}</span-->
    <!--                          >分-->
    <!--                </div>-->

    <!--              </i-circle>-->
    <!--            </div>-->
    <!--            <div-->
    <!--                style="-->
    <!--                text-align: center;-->
    <!--                width: 160px;-->
    <!--                height: 20px;-->
    <!--                line-height: 20px;-->
    <!--                font-size: 16px;-->
    <!--                font-weight: bold;-->
    <!--              "-->
    <!--            >-->
    <!--              企业基本状况-->
    <!--            </div>-->
    <!--          </Col>-->
    <!--          <Col span="6">-->
    <!--            <div class="circle">-->
    <!--              <i-circle :percent=" evalResultData.ipScore || 0 " dashboard stroke-color="#6E95FF">-->
    <!--                <div>-->
    <!--                             <span class="demo-circle-inner" style="font-size: 38px;vertical-align: text-top"-->
    <!--                             >{{ evalResultData.ipScore || 0 }}</span-->
    <!--                             >分-->
    <!--                </div>-->

    <!--              </i-circle>-->
    <!--            </div>-->
    <!--            <div-->
    <!--                style="-->
    <!--                text-align: center;-->
    <!--                width: 160px;-->
    <!--                height: 20px;-->
    <!--                line-height: 20px;-->
    <!--                font-size: 16px;-->
    <!--                font-weight: bold;-->
    <!--              "-->
    <!--            >-->
    <!--              企业知识产权-->
    <!--            </div>-->
    <!--          </Col>-->
    <!--          <Col span="6">-->
    <!--            <div class="circle">-->
    <!--              <i-circle :percent="evalResultData.financeScore || 0" dashboard stroke-color="#33C26C">-->
    <!--                <div>-->
    <!--                        <span class="demo-circle-inner" style="font-size: 38px;vertical-align: text-top"-->
    <!--                        >{{ evalResultData.financeScore  || 0}}</span-->
    <!--                        >-->
    <!--                  分-->
    <!--                </div>-->

    <!--              </i-circle>-->
    <!--            </div>-->
    <!--            <div-->
    <!--                style="-->
    <!--                text-align: center;-->
    <!--                width: 160px;-->
    <!--                height: 20px;-->
    <!--                line-height: 20px;-->
    <!--                font-size: 16px;-->
    <!--                font-weight: bold;-->
    <!--              "-->
    <!--            >-->
    <!--              企业财务状况-->
    <!--            </div>-->
    <!--          </Col>-->
    <!--          <Col span="6">-->
    <!--            <div class="circle">-->
    <!--              <i-circle :percent="evalResultData.manageScore || 0 " dashboard stroke-color="#FFA47B">-->
    <!--                <div>-->
    <!--                       <span class="demo-circle-inner" style="font-size: 38px;vertical-align: text-top"-->
    <!--                       >{{ evalResultData.manageScore || 0 }}</span-->
    <!--                       >-->
    <!--                  分-->
    <!--                </div>-->

    <!--              </i-circle>-->
    <!--            </div>-->
    <!--            <div-->
    <!--                style="-->
    <!--                text-align: center;-->
    <!--                width: 160px;-->
    <!--                height: 20px;-->
    <!--                line-height: 20px;-->
    <!--                font-size: 16px;-->
    <!--                font-weight: bold;-->
    <!--              "-->
    <!--            >-->
    <!--              企业制度建设-->
    <!--            </div>-->
    <!--          </Col>-->
    <!--        </Row>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="qyzscq" style="height: 660px; margin-top: 40px">-->
    <!--      <div-->
    <!--          style="-->
    <!--          height: 100px;-->
    <!--          line-height: 100px;-->
    <!--          font-size: 20px;-->
    <!--          font-weight: bold;-->
    <!--          padding-left: 20px;-->
    <!--        "-->
    <!--      >-->
    <!--        产学研合作需求-->
    <!--      </div>-->
    <!--      <div-->
    <!--          style="-->
    <!--          height: 100px;-->
    <!--          line-height: 100px;-->
    <!--          background-color: rgb(243, 252, 252);-->
    <!--          font-size: 14px;-->
    <!--          padding-left: 40px;-->
    <!--        "-->
    <!--      >-->
    <!--        <Row :gutter="16">-->
    <!--          <Col span="6"-->
    <!--          >企业信息匹配-->
    <!--            <span class="cq_span" style="color: rgb(54, 194, 207)"-->
    <!--            >已完成</span-->
    <!--            ></Col-->
    <!--          >-->
    <!--          <Col span="6"-->
    <!--          >其中: 符合的科研专家 <span class="cq_span">{{ expertTotal }}</span> 位-->
    <!--          </Col-->
    <!--          >-->
    <!--          <Col span="6">符合企业的专利 <span class="cq_span">{{ patentTotal }}</span> 位</Col>-->
    <!--          <Col span="6">符合的科研成果 <span class="cq_span">{{ resultTotal }}</span> 位</Col>-->
    <!--        </Row>-->
    <!--      </div>-->
    <!--      <div style="height: 460px">-->
    <!--        <Row>-->
    <!--          <Col span="12">-->
    <!--            <div id="qypp" ref="qypp" style="height: 380px"></div>-->
    <!--            <div-->
    <!--                style="-->
    <!--                height: 80px;-->
    <!--                display: flex;-->
    <!--                align-items: center;-->
    <!--                justify-content: center;-->
    <!--              "-->
    <!--            >-->
    <!--              <span style="text-align: center"> 企业匹配关键词 </span>-->
    <!--            </div>-->
    <!--          </Col>-->
    <!--          <Col span="12">-->
    <!--            <div id="xqpp" ref="xqpp" style="height: 380px"></div>-->
    <!--            <div-->
    <!--                style="-->
    <!--                height: 80px;-->
    <!--                display: flex;-->
    <!--                align-items: center;-->
    <!--                justify-content: center;-->
    <!--              "-->
    <!--            >-->
    <!--              <span style="text-align: center"> 需求匹配关键词 </span>-->
    <!--            </div>-->
    <!--          </Col>-->
    <!--        </Row>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="qyzscq" style="height: 200px; margin-top: 40px">-->
    <!--      <div-->
    <!--          style="-->
    <!--          height: 100px;-->
    <!--          line-height: 100px;-->
    <!--          font-size: 20px;-->
    <!--          font-weight: bold;-->
    <!--          padding-left: 20px;-->
    <!--        "-->
    <!--      >-->
    <!--        技术交易情况-->
    <!--      </div>-->
    <!--      <div-->
    <!--          style="-->
    <!--          height: 100px;-->
    <!--          line-height: 100px;-->
    <!--          background-color: rgb(243, 252, 252);-->
    <!--          font-size: 14px;-->
    <!--          padding-left: 40px;-->
    <!--          border-bottom: 1px solid rgb(235, 235, 235);-->
    <!--        "-->
    <!--      >-->
    <!--        <Row :gutter="16">-->
    <!--          <Col span="6"-->
    <!--          >企业购买专利-->
    <!--            <span class="cq_span">{{ transactionData.buyPatentNum }}</span>-->
    <!--            次-->
    <!--          </Col-->
    <!--          >-->
    <!--          <Col span="6"-->
    <!--          >企业购买服务 <span class="cq_span">{{ transactionData.buyServiceNum }}</span>-->
    <!--            次-->
    <!--          </Col-->
    <!--          >-->
    <!--          <Col span="6"-->
    <!--          >意向出售技术-->
    <!--            <span class="cq_span">{{ transactionData.patentSellNum }}</span>-->
    <!--            次-->
    <!--          </Col-->
    <!--          >-->
    <!--          <Col span="6"-->
    <!--          >意向求购技术-->
    <!--            <span class="cq_span">{{ transactionData.patentDemandNum }}</span>-->
    <!--            次-->
    <!--          </Col-->
    <!--          >-->
    <!--        </Row>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  </div>-->

    <!--    系统通知-->
    <Carousel :v-model="0" loop :autoplay="systemList.length>1" :autoplay-speed="8000" dots="none" arrow="never">
      <CarouselItem v-for="(item, index) in systemList" :key="index">
        <div class="ek-notice">
          <img :src="require('../../../assets/image/science/notice.png')" class="ek-notice-img" />
          <div class="ek-notice-text">
            <div class="ek-notice-text-fontA">系统通知：</div>
            <div class="ek-notice-text-fontB ellipse-1">{{ item.content }}</div>
          </div>
        </div>
      </CarouselItem>
    </Carousel>

    <!--    待办事项以及一站式服务-->
    <Row :gutter="24">
      <Col span="13">
        <div class="ek-todo" style="overflow: auto;">
          <div class="ek-to-title">
            <img :src="require('../../../assets/image/science/ent-daiban.png')" />
            <span>待办事项</span>
          </div>
          <div class="ek-td-body" v-for="(item, index) in payList" :key="index" @click="goPay(item)">
            <div class="ektb-left">
              <div class="ektb-left-fontA ellipse-1">
                {{ item.orderTypeName }}
              </div>
              <div class="ektb-left-fontB ellipse-1">
                {{ item.orderSn }}
              </div>
            </div>
            <div class="ektb-right" v-if="item.state === 'UNPAIED'">去支付</div>
          </div>
          <div v-if="payList.length<=0" style="margin-top: 40px;text-align: center">暂无数据</div>
        </div>
      </Col>
      <Col span="11">
        <div class="ek-todo" style="overflow: auto">
          <div class="ek-to-title">
            <img :src="require('../../../assets/image/science/ent_service.png')" />
            <span>一站式服务</span>
          </div>
          <div class="ek-service-list">
            <router-link :to="{path: item.goSite}" target="_blank" class="ek-service-body"
                         v-for="(item,index) in fuWuList" :key="index">
              <img
                :src="item.url" />
              <span>{{ item.name }}</span>
            </router-link>
            <div class="ek-service-body" @click="goSellerCenter">
              <img
                src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202106/seller_center.png" />
              <span>卖家中心</span>
            </div>
          </div>
        </div>
      </Col>
    </Row>

    <!--    企业知识产权状况以及企业关键词-->
    <Row :gutter="24">
      <Col span="13">
        <div class="ek-todo">
          <div class="ek-to-title">
            <img :src="require('../../../assets/image/science/patent_state.png')" />
            <span>企业知识产权状况</span>
          </div>
          <div class="ek-patent-state-list">
            <div class="ek-patent-state-body">
              <div class="ekpsb-fontA">企业托管专利</div>
              <div style="display: flex;flex-direction: row;align-items: flex-end">
                <div class="ekpsb-fontB">{{ patentMonitorData.patentMonitorTotal }}</div>
                <div class="ekpsb-fontB" style="font-size: 12px;margin-bottom: 8px">/项</div>
              </div>
            </div>
            <div class="ek-patent-state-body">
              <div class="ekpsb-fontA">实用新型专利</div>
              <div style="display: flex;flex-direction: row;align-items: flex-end">
                <div class="ekpsb-fontB">{{ patentMonitorData.syPatentNum }}</div>
                <div class="ekpsb-fontB" style="font-size: 12px;margin-bottom: 8px">/项</div>
              </div>
            </div>
            <div class="ek-patent-state-body">
              <div class="ekpsb-fontA">发明专利</div>
              <div style="display: flex;flex-direction: row;align-items: flex-end">
                <div class="ekpsb-fontB">{{ patentMonitorData.fmPatentNum }}</div>
                <div class="ekpsb-fontB" style="font-size: 12px;margin-bottom: 8px">/项</div>
              </div>
            </div>
            <div class="ek-patent-state-body" style="border-right: none">
              <div class="ekpsb-fontA">外观设计专利</div>
              <div style="display: flex;flex-direction: row;align-items: flex-end">
                <div class="ekpsb-fontB">{{ patentMonitorData.wgPatentNum }}</div>
                <div class="ekpsb-fontB" style="font-size: 12px;margin-bottom: 8px">/项</div>
              </div>
            </div>
          </div>
          <div class="ek-patent-state-list">
            <div class="ek-patent-state-body">
              <div class="ekpsb-fontA">状态变更提醒</div>
              <div style="display: flex;flex-direction: row;align-items: flex-end">
                <div class="ekpsb-fontB">0</div>
                <div class="ekpsb-fontB" style="font-size: 12px;margin-bottom: 8px">/项</div>
              </div>
            </div>
            <div class="ek-patent-state-body">
              <div class="ekpsb-fontA">年费状态预警</div>
              <div style="display: flex;flex-direction: row;align-items: flex-end">
                <div class="ekpsb-fontB">0</div>
                <div class="ekpsb-fontB" style="font-size: 12px;margin-bottom: 8px">/项</div>
              </div>
            </div>
            <div class="ek-patent-state-body">
              <div class="ekpsb-fontA">已缴纳年费</div>
              <div style="display: flex;flex-direction: row;align-items: flex-end">
                <div class="ekpsb-fontB">{{ patentMonitorData.annualFeeFinishNum }}</div>
                <div class="ekpsb-fontB" style="font-size: 12px;margin-bottom: 8px">/项</div>
              </div>
            </div>
            <div class="ek-patent-state-body" style="border-right: none">
              <div class="ekpsb-fontA">状态监控剩余</div>
              <div style="display: flex;flex-direction: row;align-items: flex-end">
                <div class="ekpsb-fontB">{{ patentMonitorData.totalMonitor }}</div>
                <div class="ekpsb-fontB" style="font-size: 12px;margin-bottom: 8px">/项</div>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col span="11">
        <div class="ek-todo">
          <div class="ek-to-title">
            <img :src="require('../../../assets/image/science/ent_key.png')" />
            <span>企业关键词</span>
          </div>
          <div class="ek-key-word" id="qypp" ref="qypp"></div>
        </div>
      </Col>
    </Row>


    <Row :gutter="24">
      <Col span="13">
        <div class="ek-todo">
          <div class="ek-to-title">
            <img :src="require('../../../assets/image/science/ent-daiban.png')" />
            <span>企业体检</span>
          </div>

          <div class="ek-test-list">
            <div class="ek-test">
              <div class="ek-test-circle" style="cursor: pointer" @click="goPingCe">
                <div class="ek-test-main" :class="{'ek-column':!evalResultData.totalScore}">
                  <span class="ek-test-main-fontA" v-if=" !evalResultData.totalScore ">立即</span>
                  <span class="ek-test-main-fontA" v-if=" !evalResultData.totalScore ">评测</span>
                  <span v-if=" evalResultData.totalScore ">{{ evalResultData.totalScore }}</span>
                  <span v-if=" evalResultData.totalScore " style="font-size:12px">分</span>
                </div>
              </div>
              <div class="ek-test-main-fontB">高新技术企业</div>
            </div>

            <div class="ek-test">
              <div class="ek-test-circle" style="background-color: rgba(15, 142, 233, 0.1)">
                <div class="ek-test-main" style="flex-direction: column;background-color: rgba(123, 165, 251, 1)">
                  <span class="ek-test-main-fontA">敬请</span>
                  <span class="ek-test-main-fontA">期待</span>
                </div>
              </div>
              <div class="ek-test-main-fontB">科技型中小企业</div>
            </div>

            <div class="ek-test">
              <div class="ek-test-circle" style="background-color: rgba(255, 191, 0, 0.1)">
                <div class="ek-test-main" style="flex-direction: column;background-color: rgba(255, 191, 0, 1)">
                  <span class="ek-test-main-fontA">敬请</span>
                  <span class="ek-test-main-fontA">期待</span>
                </div>
              </div>
              <div class="ek-test-main-fontB">科技创新小巨人企业</div>
            </div>

            <div class="ek-test">
              <div class="ek-test-circle" style="background-color: rgba(26, 195, 195, 0.1)">
                <div class="ek-test-main" style="flex-direction: column;background-color: rgba(26, 195, 195, 1)">
                  <span class="ek-test-main-fontA">敬请</span>
                  <span class="ek-test-main-fontA">期待</span>
                </div>
              </div>
              <div class="ek-test-main-fontB">双软认证</div>
            </div>
          </div>

        </div>
      </Col>
      <Col span="11">
        <div class="ek-todo">
          <div class="ek-to-title">
            <img :src="require('../../../assets/image/science/ent_service.png')" />
            <span>技术交易情况</span>
          </div>
          <div class="ek-trade">
            <div class="ek-trade-body" style="border-right: 1px dashed rgba(229, 232, 237, 1)">
              <div class="ekpsb-fontA">购买专利次数</div>
              <div style="display: flex;flex-direction: row;align-items: flex-end">
                <div class="ekpsb-fontB">{{ transactionData.buyPatentNum }}</div>
                <div class="ekpsb-fontB" style="font-size: 12px;margin-bottom: 8px">/次</div>
              </div>
            </div>
            <div class="ek-trade-body" style="border-bottom: 1px dashed rgba(229, 232, 237, 1)">
              <div class="ekpsb-fontA">购买服务次数</div>
              <div style="display: flex;flex-direction: row;align-items: flex-end">
                <div class="ekpsb-fontB">{{ transactionData.buyServiceNum }}</div>
                <div class="ekpsb-fontB" style="font-size: 12px;margin-bottom: 8px">/次</div>
              </div>
            </div>
            <div class="ek-trade-body" style="border-top: 1px dashed rgba(229, 232, 237, 1)">
              <div class="ekpsb-fontA">出售专利次数</div>
              <div style="display: flex;flex-direction: row;align-items: flex-end">
                <div class="ekpsb-fontB">{{ transactionData.patentSellNum }}</div>
                <div class="ekpsb-fontB" style="font-size: 12px;margin-bottom: 8px">/次</div>
              </div>
            </div>
            <div class="ek-trade-body" style="border-left: 1px dashed rgba(229, 232, 237, 1)">
              <div class="ekpsb-fontA">求购服务次数</div>
              <div style="display: flex;flex-direction: row;align-items: flex-end">
                <div class="ekpsb-fontB">{{ transactionData.patentDemandNum }}</div>
                <div class="ekpsb-fontB" style="font-size: 12px;margin-bottom: 8px">/次
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </div>
</template>

<script>
import {
  getSpectaculars,
  getSystem,
  getAgenda,
  getWordCloud,
  getScienceResult,
  getScienceExpert,
  getSciencePatent
} from "@/plugins/api/EnterpriseManagementCenter";
import { warnToast, errorToast } from "@/plugins/tools/util";
import { get } from "@/plugins/http/http";
import baseUrl, { qxtUrl } from "../../../plugins/http/baseUrl";
import { mapGetters } from "vuex";
import { yiZhanShiFuWu } from "../../../plugins/tools/util";
import { hostname } from "../../../config";

export default {
  name: "EnterpriseKanban",
  computed: {
    ...mapGetters({ mbrMemberId: "userStore/mbrMemberId" })
  },
  mounted() {
    this.draw();
    getScienceExpert({
      "enterpriseId": this.mbrMemberId,
      "pageSize": 1,
      "pageNum": 1,
      "appKey": "qFIYL6QWdmnj85Y8Ri24l6koGg0ayNhm"
    }).then(res => {
      if (res.code === 200) {
        this.expertTotal = res.data.total;
      }
    });
    getSciencePatent({
      "enterpriseId": this.mbrMemberId,
      "pageSize": 1,
      "pageNum": 1,
      "appKey": "qFIYL6QWdmnj85Y8Ri24l6koGg0ayNhm"
    }).then(res => {
      if (res.code === 200) {
        this.patentTotal = res.data.total;
      }
    });
    getScienceResult({
      "enterpriseId": this.mbrMemberId,
      "pageSize": 1,
      "pageNum": 1,
      "appKey": "qFIYL6QWdmnj85Y8Ri24l6koGg0ayNhm"
    }).then(res => {
      if (res.code === 200) {
        this.resultTotal = res.data.total;
      }
    });
    getSpectaculars().then((res) => {
      if (res.code === 0) {
        this.evalResultData = res.result.evalResultData || 0;
        this.transactionData = res.result.transactionData || 0;
        this.patentMonitorData = res.result.patentMonitorData || 0;
      }
    });
    getSystem().then((res) => {
      if (res.code === 0) {
        this.systemList = res.result.list;
      }
    });
    getAgenda().then((res) => {
      if (res.code === 0) {
        this.payList = res.result.filter((item, index) =>
          item.orderSn.indexOf("-") > -1);
      }
    });
    // getWordCloud().then((res) => {
    //   console.log(res);
    // });
    // this.$parent.changeHeight();
    // this.drawCloud(this.$refs.xqpp, this.cloudData);
    this.drawCloud(this.$refs.qypp, this.cloudData);
  },
  data() {
    return {
      expertTotal: 0,
      patentTotal: 0,
      resultTotal: 0,
      systemList: [],
      payList: [],
      evalResultData: {
        totalScore: 26,
        baseScore: 2,
        ipScore: 2,
        financeScore: 4,
        manageScore: 18,
        additionalScore: 0
      },
      transactionData: {
        buyPatentNum: 22,
        buyServiceNum: 20,
        patentSellNum: 11,
        patentDemandNum: 12
      },
      patentMonitorData: {
        patentMonitorTotal: 8,
        fmPatentNum: 7,
        syPatentNum: 0,
        wgPatentNum: 0,
        totalMonitor: 0,
        annualFeeFinishNum: 1
      },
      cloudData: [],
      cloudData2: [],
      fuWuList: yiZhanShiFuWu
    };
  },
  methods: {

    // 画词云
    async draw() {
      let wordCloudList = await getWordCloud({ requestId: "" });
      if (wordCloudList.code === 0) {
        if (wordCloudList.result.length > 0) {
          wordCloudList.result.forEach((item, index) => {
            this.cloudData.push({
              value: Math.round(Math.random() * 250),
              name: item.name,
              textStyle: { color: this.color() }
            });
          });
        } else {
          this.cloudData.push({
            value: Math.round(Math.random() * 250),
            name: "无",
            textStyle: { color: this.color() }
          });
        }

      }
      // this.$nextTick(() => {
      //   this.drawCloud(this.$refs.xqpp, this.cloudData);
      // });
      get(`${qxtUrl}/enterprise/enterprise/tag?memberId=${this.mbrMemberId}`).then((res) => {
        if (res.code === 0) {
          if (res.result.length > 0) {
            res.result.forEach((item, index) => {
              this.cloudData2.push({
                value: Math.round(Math.random() * 250),
                name: item.name,
                textStyle: { color: this.color() }
              });
            });
          } else {
            this.cloudData2.push({
              value: Math.round(Math.random() * 250),
              name: "无",
              textStyle: { color: this.color() }
            });
          }

        }
        this.$nextTick(() => {
          this.drawCloud(this.$refs.qypp, this.cloudData2);
        });
      });

    },

    // 待办事项
    goPay(row) {
      if (row.orderTypeName === "服务订单") {
        this.$router.push({
          name: "enterprise",
          params: {
            pgId: "serviceOrder",
            orderSn: row.orderSn
          }
        });
      } else if (row.orderTypeName === "专利订单") {
        this.$router.push({
          name: "enterprise",

          params: {
            pgId: "patentOrder",
            orderSn: row.orderSn
          }
        });
      } else {
      }
    },

    // 随机颜色
    color: function() {
      return (
        "rgb(" +
        [
          Math.round(Math.random() * 250),
          Math.round(Math.random() * 250),
          Math.round(Math.random() * 250)
        ].join(",") +
        ")"
      );
    },

    // 词云方法
    drawCloud(wrapEl, data) {
      let myChart = this.$echarts.init(wrapEl);
      var option = {
        tooltip: {
          show: true
        },
        series: [
          {
            name: "热词",
            type: "wordCloud",
            sizeRange: [10, 30],
            rotationRange: [-20, 20],
            shape: "circle",
            left: "center",
            top: "center",
            width: "100%",
            height: "100%",
            gridSize: 3,
            textPadding: 0,
            autoSize: {
              enable: true,
              minSize: 6
            },
            data: data
          }
        ]
      };
      myChart.setOption(option);
    },
    goSellerCenter() {
      window.open("http://seller." + hostname + "/broker-dist/");
    },
    goPingCe() {
      this.$router.push({ path: "/enterprise/enterprise-physical-examination" });
    }
  }
};
</script>

<style lang="scss" scoped>
.e-kanban {
  background-color: rgba(247, 248, 249, 1);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ek-notice {
  width: 100%;
  height: 80px;
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;

}

.ek-notice-img {
  width: 48px;
  height: 48px;
}

.ek-notice-text {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  overflow: hidden;
}

.ek-notice-text-fontA {
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC, serif;
  font-weight: 600;
  color: #333333;
}

.ek-notice-text-fontB {
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC, serif;
  font-weight: 400;
  color: #666666;
}

.ek-todo {
  width: 100%;
  background-color: #FFFFFF;
  margin-top: 20px;
  border-radius: 8px;
  padding: 0 20px 20px 20px;
  height: 280px;

}

.ek-to-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 43px;
  border-bottom: 1px dashed #E5E8ED;

  img {
    width: 16px;
    height: 16px;
  }

  span {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC, serif;
    margin-left: 12px;
    font-weight: 500;
    color: #333333;
  }

}

.ek-td-body {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  height: 56px;
  align-items: center;
  justify-content: space-between;
  padding-left: 14px;
  padding-right: 14px;
  background-color: rgba(247, 248, 249, 1);
  margin-top: 12px;
  cursor: pointer;
}

.ek-td-body:hover {
  background-color: rgba(24, 144, 255, 1);
}

.ek-td-body:hover > .ektb-left > .ektb-left-fontA {
  color: #FFFFFF;
}

.ek-td-body:hover > .ektb-left > .ektb-left-fontB {
  color: #FFFFFF;
}

.ek-td-body:hover > .ektb-right {
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
}

.ektb-left {
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
}

.ektb-left-fontA {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC, serif;
  font-weight: 500;
  color: #333333;
}

.ektb-left-fontB {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC, serif;
  font-weight: 400;
  color: #999999;
  margin-top: 3px;
}

.ektb-right {
  width: 56px;
  height: 28px;
  border-radius: 4px;
  border: 1px solid #FFA004;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC, serif;
  font-weight: 400;
  color: #FFA004;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
}

.ek-service-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ek-service-body {
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 18px;

  img {
    width: 40px;
    height: 40px;
  }

  span {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC, serif;
    font-weight: 400;
    color: #666666;
    margin-top: 8px;
  }
}

.ek-service-body:hover {
  box-shadow: 0 5px 20px 0 rgba(13, 24, 43, 0.08);
  border-radius: 4px;
}

.ek-patent-state-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 80px;
  align-items: center;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 4px;
  border: 1px solid #E5E8ED;
  margin-top: 20px;
}

.ek-patent-state-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  border-right: 1px dashed #E5E8ED;
}

.ekpsb-fontA {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC, serif;
  font-weight: 400;
  color: #666666;
}

.ekpsb-fontB {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC, serif;
  font-weight: 500;
  color: #333333;
}

.ek-key-word {
  width: 100%;
  height: 100%;
  padding: 20px;
}

.ek-trade {
  height: 237px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ek-trade-body {
  width: 50%;
  height: 50%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ek-test-list {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  width: 100%;
  justify-content: space-between;
}

.ek-test {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ek-test-circle {
  width: 100px;
  height: 100px;
  background-color: rgba(255, 112, 67, 0.1);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ek-test-main {
  width: 80px;
  height: 80px;
  background: #FF7043;
  opacity: 0.85;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-family: DINPro-Medium, DINPro, serif;
  font-weight: 500;
  color: #FFFFFF;
}

.ek-test-main-fontA {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC, serif;
  font-weight: 500;
  color: #FFFFFF;
}

.ek-test-main-fontB {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC, serif;
  font-weight: 400;
  color: #666666;
  margin-top: 20px;
}

.ek-column {
  display: flex;
  flex-direction: column;
}
</style>
